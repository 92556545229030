export enum QuestionnaireErrorCodes {
	UNKNOWN = 'ERROR_UNKNOWN',
}

export interface QuestionnaireQuestion {
	category: {
		name: string;
		description: string;
	};
	question: {
		id: number;
		title: string;
		description: string;
		answers: Array<QuestionnaireAnswer>;
	};
	progress: QuestionnaireProgress;
}

export interface QuestionnaireProgress {
	categoryCount: number;
	currentCategoryIndex: number;
	remainingQuestionCount: number;
	totalQuestionCount: number;
}

export interface QuestionnaireAnswer {
	id: number;
	answer: string;
}
