import {
	HttpClient,
	HttpErrorResponse,
	HttpParams,
} from '@angular/common/http';
import { Inject, Injectable, computed, signal } from '@angular/core';
import { ApiErrorHelper } from '@nx/core/lib/helpers/api-error/api-error.helper';
import {
	LoaderState,
	LoadingStates,
} from '@nx/core/lib/interfaces/loading-states.interface';
import {
	QuestionnaireErrorCodes,
	QuestionnaireQuestion,
} from '@nx/core/lib/interfaces/questionnaire.interface';
import { AddAnswerRequestAPI, QuestionResponseAPI } from 'api-interfaces';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class QuestionnaireService {
	private _questionnaireStateSig = signal<
		LoaderState<QuestionnaireQuestion, string>
	>({
		state: LoadingStates.IDLE,
	});
	questionnaireStateComputed = computed(() => this._questionnaireStateSig());

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	getQuestionnaire(data: {
		deceasedID: string;
		categoryID?: number;
	}): Observable<QuestionnaireQuestion | undefined> {
		this._questionnaireStateSig.set({
			state: LoadingStates.LOADING,
		});

		const params: HttpParams = new HttpParams({
			fromObject: {
				deceased: data.deceasedID,
				...(data.categoryID !== undefined
					? { categoryid: data.categoryID }
					: {}),
			},
		});
		return this.http
			.get<QuestionResponseAPI>(`${this.endpoint}v1/questionnaire`, {
				params,
			})
			.pipe(
				map((response) => this.handleQuestionSuccess(response)),
				catchError((errorResponse) =>
					this.handleQuestionError(errorResponse),
				),
			);
	}

	getNextQuestion(data: {
		deceasedID: string;
		questionID: number;
		categoryID?: number;
		answerOptionID: number;
		skipCategory: boolean;
	}): Observable<QuestionnaireQuestion | undefined> {
		this._questionnaireStateSig.set({
			state: LoadingStates.LOADING,
		});

		const body: AddAnswerRequestAPI = {
			deceased: data.deceasedID,
			questionID: data.questionID,
			answerOptionID: data.answerOptionID,
			skipCategory: data.skipCategory,
			...(data.categoryID
				? {
						categoryID: data.categoryID,
				  }
				: {}),
		};

		if (data.skipCategory) {
			delete body.answerOptionID;
		}

		return this.http
			.post<QuestionResponseAPI>(
				`${this.endpoint}v1/questionnaire`,
				body,
				{},
			)
			.pipe(
				map((response) => this.handleQuestionSuccess(response)),
				catchError((errorResponse) =>
					this.handleQuestionError(errorResponse),
				),
			);
	}

	resetQuestionnaireState() {
		this._questionnaireStateSig.set({
			state: LoadingStates.IDLE,
		});
	}

	private handleQuestionSuccess(
		response: QuestionResponseAPI,
	): QuestionnaireQuestion {
		const data = this.parseQuestion(response);
		this._questionnaireStateSig.set({
			state: LoadingStates.LOADED,
			data,
		});
		return data;
	}

	private handleQuestionError(
		errorResponse: HttpErrorResponse,
	): Observable<undefined> {
		const error = ApiErrorHelper.getError<QuestionnaireErrorCodes>(
			errorResponse,
			QuestionnaireErrorCodes,
		);

		this._questionnaireStateSig.set({
			state: LoadingStates.ERROR,
			error: error,
		});

		return of(undefined);
	}

	private parseQuestion(
		response: QuestionResponseAPI,
	): QuestionnaireQuestion {
		return {
			category: {
				name: response.data.category?.name || '',
				description: response.data.category?.description || '',
			},
			question: {
				id: response.data.question?.id || 0,
				title: response.data.question?.title || '',
				description: response.data.question?.description || '',
				answers:
					response.data.question?.answers?.map((answer) => ({
						id: answer.id,
						answer: answer.answer,
					})) || [],
			},
			progress: {
				categoryCount: response.data.progress?.totalCategories || 0,
				currentCategoryIndex:
					response.data.progress?.currentCategory - 1 || 0,
				remainingQuestionCount:
					Math.max(response.data.progress?.questionsLeft, 0) || 0,
				totalQuestionCount: response.data.progress?.totalQuestions || 0,
			},
		};
	}
}
